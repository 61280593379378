import React, { useState, useRef, useEffect } from "react";
import "./home.css";
import axios from "axios";

// 로컬일 때 BASE_URL을 localhost:5000으로 써야하고 아니면 위의 아이피 주소로 써야 함.
// const BASE_URL = 'https://54.180.121.67'
const BASE_URL = "https://sickgpt-api.x2s.pw";

export default function Home() {
  const [image, setImage] = useState(null);
  const [TTSImage, setTTSImage] = useState(null);
  const [text, setText] = useState("");
  const [chatId, setChatId] = useState("");
  const [result, setResult] = useState("");
  const [communication, setCommunication] = useState("");
  const [communicationTitle, setCommunicationTitle] = useState("추가 질문사항");
  const [textAreaHeight, setTextAreaHeight] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  // 메시지를 담는 배열과 새 메시지를 저장하는 state
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const chatBottomRef = useRef(null);

  const textarea = useRef(null);
  const synth = window.speechSynthesis;

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
    setText("");
    setResult("");
    setCommunication("");
    setCommunicationTitle("추가 질문사항");
    const previewImageUrl = URL.createObjectURL(selectedImage);
    setPreviewImage(previewImageUrl);
  };

  useEffect(() => {
    handleClickListen(result);
  }, [result]);

  useEffect(() => {
    handleClickListen(communication);
  }, [communication]);

  useEffect(() => {
    // 최신 메시지가 항상 하단에 위치하도록 스크롤 조정
    if (chatBottomRef.current) {
      chatBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleTTSImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setTTSImage(selectedImage);
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      setIsLoading(false);
      if (typeof error.response === "undefined") {
        console.dir(error);
        return console.log("network error");
      }
      if (error.response.status === 401) {
        // Authorization error
        console.log("network error - 401");
      } else if (error.response.status === 500) {
        // Server error
        console.log("network error - 500-error");
      } else {
        return Promise.reject(error);
      }
    }
  );

  const updateHeight = () => {
    let textAreaContent = textarea.current;
    textAreaContent.style.height = "auto";
    textAreaContent.style.height = textAreaContent.scrollHeight + "px";
    setTextAreaHeight(textAreaContent.style.height);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    updateHeight();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("image", image);

    try {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}/upload`, formData, {
          timeout: 0,
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setIsLoading(false);
          const text = response.data.text;
          const chatId = response.data.chatId;
          setText(text);
          setChatId(chatId);
          setTimeout(function () {
            updateHeight();
          }, 300);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleTTSSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("image", TTSImage);

    try {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}/upload-user-image`, formData, {
          timeout: 0,
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setIsLoading(false);
          console.log(response);
          response.data.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "output.mp4");
            // append the link to the document body and click it programmatically
            document.body.appendChild(link);
            link.click();
            alert("It is downloaded!");
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleTranslate = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      axios
        .post(
          `${BASE_URL}/translate`,
          { text: text, chatId: chatId },
          {
            timeout: 0,
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
            timeout: 600000,
          }
        )
        .then((response) => {
          setIsLoading(false);
          const text = response.data.text;
          const chatId = response.data.chatId;
          setResult(text);
          setChatId(chatId);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleClickListen = (text) => {
    if (synth.speaking) {
      synth.cancel();
    }
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
  };

  const handleClickCommunication = (title, message) => {
    const myObj = {
      from: "Me",
      content: title,
    };
    try {
      setIsLoading(true);
      let chat = "";
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].from === "Me") {
          chat += "질문 : " + messages[i].content + "..\n";
        } else {
          chat += "답변 : " + messages[i].content + "..\n";
        }
      }
      setMessages([...messages, myObj]);
      axios
        .post(
          `${BASE_URL}/chat`,
          { result: result, chat: chat, question: message, chatId: chatId },
          {
            timeout: 0,
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
            timeout: 600000,
          }
        )
        .then((response) => {
          setIsLoading(false);
          const text = response.data.text.replace("답변 : ", "");
          const botObj = {
            from: "SickGPT",
            content: text,
          };
          setMessages([...messages, myObj, botObj]);
          handleClickListen(text);
          setChatId(response.data.chatId);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // 입력된 메시지를 저장하고 state를 초기화하는 함수
  const sendMessage = () => {
    const m = newMessage.trim();
    if (m !== "") {
      const myObj = {
        from: "Me",
        content: m,
      };
      setNewMessage("");
      try {
        setIsLoading(true);
        let chat = "";
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].from === "Me") {
            chat += "질문 : " + messages[i].content + "..\n";
          } else {
            chat += "답변 : " + messages[i].content + "..\n";
          }
        }
        setMessages([...messages, myObj]);
        axios
          .post(
            `${BASE_URL}/chat`,
            { result: result, chat: chat, question: m, chatId: chatId },
            {
              timeout: 0,
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
              timeout: 600000,
            }
          )
          .then((response) => {
            setIsLoading(false);
            const text = response.data.text.replace("답변 : ", "");
            const botObj = {
              from: "SickGPT",
              content: text,
            };
            setMessages([...messages, myObj, botObj]);
            handleClickListen(text);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
          });
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  // 엔터키를 누르면 sendMessage 함수 호출
  const handleKeyDown = (event) => {
    console.log("handleKeyDown is processed...");
    if (event.key === "Enter") {
      console.log("handleKeyDown Enter is processed!");
      sendMessage();
    }
  };

  // 메시지 입력창에서 state 업데이트
  const handleChange = (event) => {
    setNewMessage(event.target.value);
  };

  return (
    <div>
      {isLoading ? (
        <div className="prevent-click-background">
          <div className="loading-spinner">
            <div className="loading-spinner-circle"></div>
          </div>
        </div>
      ) : null}
      <div style={{ margin: "0 auto", maxWidth: "768px" }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          {/* 로고 img에 채워 넣어야 함 */}
          <a href="/">
            <img src="./logo.png" />
          </a>
        </div>
        <div
          style={{
            clear: "both",
            textAlign: "center",
            width: "calc(100% - 48px)",
            margin: "0px 12px 12px 12px",
            padding: "12px",
            lineHeight: "1.5em",
          }}
        >
          SickGPT에 오신 여러분 환영합니다.
          <br />
          <strong>'의무기록 이미지'</strong>를 선택하거나,{" "}
          <strong>'의무기록 텍스트'</strong>에 의무기록을 직접 입력해주세요.
        </div>
        <form onSubmit={handleFormSubmit}>
          <div
            style={{
              clear: "both",
              border: "1px solid #787878",
              width: "calc(100% - 48px)",
              margin: "12px 12px 0px 12px",
              padding: "12px",
            }}
          >
            <h2 style={{ margin: "0" }}>의무기록 이미지</h2>
            <input
              type="file"
              onChange={handleImageChange}
              style={{ width: "100%" }}
            />
            {previewImage && (
              <img src={previewImage} alt="preview" style={{ width: "100%" }} />
            )}
          </div>
          <button type="submit" style={{ float: "right", margin: "12px" }}>
            텍스트 추출하기
          </button>
        </form>
        <div
          style={{
            clear: "both",
            border: "1px solid #787878",
            width: "calc(100% - 48px)",
            margin: "12px 12px 0px 12px",
            padding: "12px",
          }}
        >
          <h2 style={{ margin: "0" }}>의무기록 텍스트</h2>
          <textarea
            ref={textarea}
            value={text}
            onChange={handleTextChange}
            style={{
              height: textAreaHeight,
              width: "calc(100% - 12px)",
              border: "1px solid #787878",
              marginTop: "12px",
            }}
          ></textarea>
        </div>
        <button
          type="button"
          onClick={handleTranslate}
          style={{ float: "right", margin: "12px" }}
        >
          의무기록 분석하기
        </button>
        <div
          style={{
            clear: "both",
            border: "1px solid #787878",
            width: "calc(100% - 48px)",
            margin: "12px 12px 0px 12px",
            padding: "12px",
          }}
        >
          <h2 style={{ margin: "0px" }}>의무기록 분석결과</h2>
          {result && <p style={{ marginTop: "8px" }}>{result}</p>}
        </div>
        {result === "" ? null : (
          <>
            <div className="chat-container">
              <div className="chat">
                <h2 style={{ margin: "0", padding: "12px" }}>SickGPT와 채팅</h2>
                <div style={{ width: "100%" }}>
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "0px 12px 12px 6px",
                      width: "180px",
                      display: "inline-block",
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClickCommunication(
                        "주의해야 할 사항은 무엇일까요?",
                        "지금부터 주의해야 할 주의사항이 뭐야?"
                      );
                    }}
                  >
                    주의해야 할 사항
                  </button>
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "0px 6px 12px 6px",
                      width: "180px",
                      display: "inline-block",
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClickCommunication(
                        "환자의 상태는 어떤가요?",
                        "이런 증상이 있는 환자들이 얼마나 있는지 평균적으로 이런 증상이 있는 환자들 중에 좋은 상태인지 안 좋은 상태인지 알려줄래?"
                      );
                    }}
                  >
                    환자의 상태
                  </button>
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "0px 6px 12px 6px",
                      width: "180px",
                      display: "inline-block",
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClickCommunication(
                        "건강식품 추천해주세요.",
                        "추천 해줄만한 건강식품이나 음식을 알려줄래?"
                      );
                    }}
                  >
                    건강식품 추천
                  </button>
                </div>
                <div className="chat__messages">
                  {/* 메시지 배열을 map으로 순환하면서 메시지 출력 */}
                  {messages.map((message, index) => (
                    <div className="chat__message_box" key={index}>
                      {message.from === "SickGPT" ? (
                        <div className="bot">{message.from}</div>
                      ) : (
                        <div className="me">{message.from}</div>
                      )}
                      <div className="chat__message">{message.content}</div>
                    </div>
                  ))}
                  <div ref={chatBottomRef} />
                </div>
                <div className="chat__input">
                  <input
                    type="text"
                    placeholder="메시지를 입력하세요"
                    value={newMessage}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <button type="button" onClick={sendMessage}>
                    전송
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        <div style={{ clear: "both", marginBottom: "12px" }}></div>
      </div>
      <div
        style={{
          background: "#787878",
          color: "white",
          padding: "64px 32px",
          textAlign: "center",
        }}
      >
        Copyright © sick-gpt.com. All Rights Reserved.
      </div>
    </div>
  );
}
